import Utils from "@/vue/infrastructure/utils/helpers";
import { Component, Vue } from "vue-property-decorator";
import DfStore from "@/vue/domain/store/df-store";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const DfStoreCardComponent = () => Utils.externalComponent2("df-store-card");
const DfHeaderMobileComponent = () => Utils.externalComponent2("df-header-mobile");

@Component({
  components: {
    DfHeaderMobile: DfHeaderMobileComponent,
    DfStoreCard: DfStoreCardComponent,
    LMap,
    LTileLayer,
    LMarker,
  },
})
export default class DfPageStoreComponent extends Vue {
  private storeMapZoom = 13;
  private storeMapUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

  get defaultMapPreview(): string {
    return `${Utils.getPublicPath()}/assets/default-map.png`;
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get storeMapCenter(): Array<string> {
    return this.currentStore.gpsCoordinates
      ? [
          (this.currentStore.gpsCoordinates.latitude + 0.01).toString(),
          this.currentStore.gpsCoordinates.longitude.toString(),
        ]
      : null;
  }

  get storeMarkerPosition(): Array<string> {
    return this.currentStore.gpsCoordinates
      ? [
          this.currentStore.gpsCoordinates.latitude.toString(),
          this.currentStore.gpsCoordinates.longitude.toString(),
        ]
      : null; // ['45.76478506922795','8.811687255823038'];
  }

  beforeMount() {
    Utils.setAnaliticsTraker({
      event: "show_store_info",
      origin: "smt-digitalflyer-ui",
      storeAlias: this.currentStore.alias,
      storeCode: this.currentStore.code,
      storeName: this.currentStore.name,
    });
  }

  private goToStoreChange() {
    this.$router.push({ name: "store-change", params: { storeAlias: this.currentStore.alias } }).catch((err) => err);
  }

  private openMap() {
    window.open(
      `${Utils.GOOGLE_MAP_URL}${Utils.storeGoogleMapsCoordinates(
        this.currentStore
      )}`,
      "_blank"
    );
  }
}
